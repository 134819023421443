import React from 'react'
import parse from 'html-react-parser'

const Default = ({ page }) => {
  return (
    <div>
      <div className="section-header"></div>
      <div className="box box--start">
        <h1 className={`${page.title.length > 6 ? 'heading--long' : ''} mobileFixed`}>
          {page.title}
        </h1>
        {/* <h2>{page.subtitleAcf.subtitleacf}</h2> */}
        {/* <h3>{page.subtitleAcf.beforeimgacf}</h3> */}
        {parse(page.content || '')}

        {/* {page.subtitleAcf.buttonlinkacf && <Btn to={pageContext.sitePath.slice(0, -1) + page.subtitleAcf.buttonlinkacf}>{page.subtitleAcf.buttonacf}</Btn>} */}

      </div>
    </div>
  )
}

export default Default
