import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'
import { navigate } from "gatsby"

const ThankYou = ({ page, location }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BusinessThankyouPosts {
      thankyou: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {      
				acfbusinesspagethankyou {
					subheaderBusinessPageThankYou
          subheader2BusinessPageThankYou
          ctaTextBusinessPageThankYou
          ctaLinkBusinessPageThankYou
          redirectLabelBusinessPageThankYou
          redirectTimeBusinessPageThankYou
        }
      }
    }
  `)

  // useEffect(() => {

  //   if(typeof window !== 'undefined') {

  //     if(typeof window.dataLayer !== 'undefined') {
        
  //       window.dataLayer.push({
  //         // 'event' : 'contactFormSubmission-Business',
  //         'event' : 'pageview',
  //         'pageType' : 'ThankYouPage-Business',
  //       });
        
  //       console.log('DATALAYER PUSH')
  //     }
  //   }
  // }, [])

  const redirect = location.search !== '' ? location.search.slice(1) : graphqlResult.thankyou.acfbusinesspagethankyou.ctaLinkBusinessPageThankYou === null ? '/' : graphqlResult.thankyou.acfbusinesspagethankyou.ctaLinkBusinessPageThankYou

  const[count, setCount] = useState(graphqlResult.thankyou.acfbusinesspagethankyou.redirectTimeBusinessPageThankYou)
  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1)
    }, 1000);
    return () => clearTimeout(timer);
  }, [count])

  useEffect(() => {
    if(count === 0) {
      navigate(redirect)
    }
  }, [count])


  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--order thankU">
        <div className={`boxDeco`}>
            <SvgBoxDeco />
        </div>
        <h2>{graphqlResult.thankyou.acfbusinesspagethankyou.subheaderBusinessPageThankYou}</h2>
        <h3>{graphqlResult.thankyou.acfbusinesspagethankyou.subheader2BusinessPageThankYou}</h3>
        {graphqlResult.thankyou.acfbusinesspagethankyou.ctaLinkBusinessPageThankYou && 
        <>
          <AniLink 
            className="link-back cta2" 
            // to={graphqlResult.thankyou.acfbusinesspagethankyou.ctaLinkBusinessPageThankYou}
            to={redirect}
            duration={1.6}
            swipe 
            direction="right"
            entryOffset={400}
            data-hover={graphqlResult.thankyou.acfbusinesspagethankyou.ctaTextBusinessPageThankYou}
            style={{width:'300px',marginLeft:'auto',marginRight:'auto'}}
          >
            {graphqlResult.thankyou.acfbusinesspagethankyou.ctaTextBusinessPageThankYou}
          </AniLink>
          <span style={{color:'white',fontSize:'16px'}}>
            {graphqlResult.thankyou.acfbusinesspagethankyou.redirectLabelBusinessPageThankYou}
            {count}
          </span>
        </>
        }
      </div>
    </div>
  )
}
export default ThankYou