import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import FbIco from 'src/images/socialIcons/facebook.js'
import InIco from 'src/images/socialIcons/instagram.js'
import LiIco from 'src/images/socialIcons/linkedin.js'
import PiIco from 'src/images/socialIcons/pinterest.js'
import YtIco from 'src/images/socialIcons/youtube.js'

import ContactForm from 'src/components/common/contactForm'

const Contact = ({ page, pageContext }) => {
  const pagePosts = useStaticQuery(graphql`
    query BusinessContactPosts {
      allWpCptBusinessTeam {
        edges {
          person: node {
            id
            title
            acfteam {
              emailTeamMembers
              phoneTeamMembers
              phoneAltTeamMembers
              positionTeamMembers
              langTeamMembers
            }
            featuredImage {
              node {
                altText
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 125, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      generalInfo: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "footer-info-column"}}) {
        acfgeneralinfosfootercolumn_layout {
          line0GenralInfosFooterColumn
          line1GenralInfosFooterColumn
          line2GenralInfosFooterColumn
          line3GenralInfosFooterColumn
          line4GenralInfosFooterColumn
          line5GenralInfosFooterColumn
          line6GenralInfosFooterColumn
          line7GenralInfosFooterColumn
          line8GenralInfosFooterColumn
          line9GenralInfosFooterColumn
          line10GenralInfosFooterColumn
          line11GenralInfosFooterColumn
          line12GenralInfosFooterColumn
          line13GenralInfosFooterColumn
        }
      } 
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      contact: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "contact"}}) {      
        acfbusinesspagecontact {
          subheaderBusinessPageContact
          subheader2BusinessPageContact
        }
      }
      thankyou: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {
        slug
      }
    }
  `)
  const team = pagePosts.allWpCptBusinessTeam.edges

  const currentLang = pagePosts.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHref = pagePosts.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHref2 = pagePosts.defaultPhone.acfgeneralinfos_layout.line2GenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')
  const phoneHrefClean2 = phoneHref2 ? phoneHref2.replace(/\s+/g, '') : ''
  const footerInfo = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line0GenralInfosFooterColumn
  const footerInfo1 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line1GenralInfosFooterColumn
  const footerInfo2 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line2GenralInfosFooterColumn
  const footerInfo3 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line3GenralInfosFooterColumn
  const footerInfo4 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line4GenralInfosFooterColumn
  const footerInfo5 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line5GenralInfosFooterColumn
  const footerInfo6 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line6GenralInfosFooterColumn
  const footerInfo7 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line7GenralInfosFooterColumn
  const footerInfo8 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line8GenralInfosFooterColumn
  const footerInfo9 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line9GenralInfosFooterColumn
  const footerInfo10 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line10GenralInfosFooterColumn
  const footerInfo11 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line11GenralInfosFooterColumn
  const footerInfo12 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line12GenralInfosFooterColumn
  const footerInfo13 = pagePosts.generalInfo.acfgeneralinfosfootercolumn_layout.line13GenralInfosFooterColumn

  return (
    <div className="contact">
      <div className="section section--team" >
        <div className="section-header">
          <h1>
            <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
              {page.title}
            </span>
          </h1>
          <h2>{pagePosts.contact.acfbusinesspagecontact.subheaderBusinessPageContact}</h2>
          <div className={`wpContent noMobile`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        </div>
        <div 
          className="section-body" 
        >
          {[...team].reverse().map(({ person }) => {
            const featuredImage = {
              fluid: person.featuredImage?.node?.localFile?.childImageSharp?.fluid,
              alt: person.featuredImage?.node?.altText || ``,
              title: person.featuredImage?.node?.title || ``,
            }
            return (
              <div className={`person ${person.acfteam.langTeamMembers === 'de' ? 'langDe' : ''}`} key={person.id}>
                <div className="image-wrapper">
                  <div className="image" style={{maxWidth:'125px'}}>
                    {featuredImage?.fluid && (
                      <Image 
                        fluid={featuredImage.fluid} 
                        alt={featuredImage.alt}
                        title={featuredImage.title}
                      />
                    )}
                  </div>
                </div>
                <h3>{person.title}</h3>
                <span style={{fontSize:'14px'}}>{person.acfteam.positionTeamMembers}</span>
                {person.acfteam.phoneTeamMembers && <a href={`tel:${person.acfteam.phoneTeamMembers.replace(/\s+/g, '')}`}>
                  <span className="person_phone cta2">{person.acfteam.phoneTeamMembers}</span>
                </a>}
                {person.acfteam.phoneAltTeamMembers && <a href={`tel:${person.acfteam.phoneAltTeamMembers.replace(/\s+/g, '')}`}>
                  <span className="person_phone cta2">{person.acfteam.phoneAltTeamMembers}</span>
                </a>}
                <a href={`mailto:${person.acfteam.emailTeamMembers}`}>
                  <span className="person_email cta2">{person.acfteam.emailTeamMembers}</span>
                </a>
              </div>
            )
          })}

          <div className={`socIcoWrap`}>
              <a href="https://www.facebook.com/themodernforms" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
                <FbIco />
              </a>
              <a href="https://www.instagram.com/themodernforms/" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
                <InIco />
              </a>
              <a href="https://www.linkedin.com/company/modern-forms" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
                <LiIco />
              </a>
              <a href="https://pl.pinterest.com/themodernforms/_created/" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
                <PiIco />
              </a>
              <a href="https://www.youtube.com/channel/UCPmAyp3GvSFdkBWlIeHnZOQ" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
                <YtIco />
              </a>
            </div>

          {/* <div className={`contact-info`}> */}
            {/* <h5>{currentLang === 'de-DE' ? `Unternehmensdaten` : currentLang === 'pl-PL' ? 'Dane firmy' : `Company's data`}</h5> */}
            {/* <span>{footerInfo && footerInfo}</span> */}
            {/* <span>{footerInfo1 && footerInfo1}</span> */}
            {/* <span>{footerInfo2 && footerInfo2}</span> */}
            {/* <span>{footerInfo3 && footerInfo3}</span> */}
            {/* <span className={`account first`}><strong>{footerInfo4 && footerInfo4}</strong></span> */}
            {/* <span className={`account xx`}>{footerInfo5 && footerInfo5}</span> */}
            {/* <span>{footerInfo6 && footerInfo6}</span> */}
            {/* <span className={`account`}>{footerInfo7 && footerInfo7}</span> */}
            {/* <span className={`account xx`}>{footerInfo8 && footerInfo8}</span> */}
            {/* <span className={`account`}>{footerInfo9 && footerInfo9}</span> */}
            {/* <span className={`account xx`}>{footerInfo10 && footerInfo10}</span> */}
            {/* <span className={`account`}>{footerInfo11 && footerInfo11}</span> */}
            {/* <span className={`account xx`}>{footerInfo12 && footerInfo12}</span> */}
            {/* <span className={`account`}>{footerInfo13 && footerInfo13}</span> */}
            {/* <a href={`mailto:${footerInfo6}`} className={`cta2`}>{footerInfo6}</a> */}
            {/* <a href={`tel:${currentLang !== 'de-DE' ? phoneHrefClean : phoneHrefClean2}`} className={`cta2`}>{currentLang !== 'de-DE' ? phoneHref : phoneHref2}</a> */}
          {/* </div> */}

        </div>
      </div>
      <div className="section section--form">
        <h2>
          {pagePosts.contact.acfbusinesspagecontact.subheader2BusinessPageContact}
        </h2>
        <ContactForm pageContext={pageContext} thankyou={pagePosts.thankyou.slug} subscription={page.acffreshmailsubs} />
      </div>
    </div>
  )
}
export default Contact