import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'

const Order = ({ page, pageContext }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BusinessOrderPosts {
      placingOrder: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "placing-order"}}) {
        acfbusinesspageplacingorder {
          cta2LinkBusinessPagePlacingOrder
          cta2TextBusinessPagePlacingOrder
          ctaLinkBusinessPagePlacingOrder
          ctaTextBusinessPagePlacingOrder
          subheader2BusinessPagePlacingOrder
          subheaderBusinessPagePlacingOrder
        }
      }
      howtoorder: wpPage(AcfGatsbyId: {pageid: {eq: "how-to-order"}}) {
        slug
        acfpagehowtoorder {
          mailLabelPageHowToOrder
        }
      }
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      defaultMail: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-email"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const phoneHref = graphqlResult.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')

  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--order boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        <h2>{graphqlResult.placingOrder.acfbusinesspageplacingorder.subheaderBusinessPagePlacingOrder}</h2>
        {page.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        <h3>{graphqlResult.placingOrder.acfbusinesspageplacingorder.subheader2BusinessPagePlacingOrder}</h3>
        {graphqlResult.placingOrder.acfbusinesspageplacingorder.ctaLinkBusinessPagePlacingOrder && 
        <AniLink 
          className="link fakeButton cta" 
          to={graphqlResult.placingOrder.acfbusinesspageplacingorder.ctaLinkBusinessPagePlacingOrder} 
          duration={1.6}
          entryOffset={400}
          swipe 
          direction="left"
          data-hover={graphqlResult.placingOrder.acfbusinesspageplacingorder.ctaTextBusinessPagePlacingOrder}
        >
          <span>{graphqlResult.placingOrder.acfbusinesspageplacingorder.ctaTextBusinessPagePlacingOrder}</span>
        </AniLink>}
        <a
          className={`link fakeButton cta`}
          href={`tel:${phoneHrefClean}`}
          data-hover={graphqlResult.placingOrder.acfbusinesspageplacingorder.ctaTextBusinessPagePlacingOrder}
        >
          <span>{graphqlResult.placingOrder.acfbusinesspageplacingorder.ctaTextBusinessPagePlacingOrder}</span>
        </a>
        {graphqlResult.placingOrder.acfbusinesspageplacingorder.cta2TextBusinessPagePlacingOrder && 
        <AniLink
          className="howToOrder-link cta2"
          to={graphqlResult.placingOrder.acfbusinesspageplacingorder.cta2LinkBusinessPagePlacingOrder}
          duration={1.6}
          swipe
          direction="up"
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
          dangerouslySetInnerHTML={{ __html: graphqlResult.placingOrder.acfbusinesspageplacingorder.cta2TextBusinessPagePlacingOrder }}
        >
        </AniLink>}
        <h3>{graphqlResult.howtoorder.acfpagehowtoorder.mailLabelPageHowToOrder}</h3>
        <a
          href={`mailto:${graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo}`}
          style={{color:'white',textDecoration:'none'}}
        >
          {graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo}
        </a>
      </div>
    </div>
  )
}
export default Order