import React, { useContext, useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'
import SvgPlay from 'src/images/playButton'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

const Start = ({ page }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const graphqlResult = useStaticQuery(graphql`
    query BusinessStartPage {
      start: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "start"}}) {      
        acfbusinesspagestart {
          subheader2BusinessPageStart
          subheaderBusinessPageStart
          ctaTextBusinessPageStart
          ctaLinkBusinessPageStart
        }
      }
    }
  `)

  /*    VIDEO HANDLLING   */
  function playVideo() {
    dispatch({ type: 'PLAY_START_BUSINESS' })
  }
  const hasPlayer = playVideo
  /*    END VIDEO HANDLLING   */

  let containerClass = state.playStartBusiness ? "videoPlaying" : "videoStoped"




  /*    BOX MEASURE    */
  function boxInitial() {
    return 0
  }
  const boxHeightRef = useRef(null)
  const[boxHeight, setBoxHeight] = useState(() => boxInitial())

  const boxExtTop = state.windowMode === 'higher' ? `${((state.windowWidth  - 225) * 0.5625) / 2 }px` : `${(state.windowHeight - 225) / 2}px`
  
  useEffect(() => {
  // useLayoutEffect(() => {
    
    const bHeight = boxHeightRef.current.getBoundingClientRect()
    setBoxHeight(parseInt(bHeight.height))
    
  }, [state.windowWidth]) // eslint-disable-line react-hooks/exhaustive-deps


  /*    VISUAL EFECT FOR VIDEO SHOW    */
  const videoClassTrue = () => {
    document.body.classList.add('startVideoShowStatic')
    document.body.classList.add('startVideoShow')
  }
  const videoClassFalse = () => {
    document.body.classList.remove('startVideoShowStatic')
    document.body.classList.add('startVideoHide')
  }
  useLayoutEffect(() => {  
    state.playStartBusiness ?    
    setTimeout(() => {
      videoClassTrue()
    }, 333) 
    :
    setTimeout(() => {
      videoClassFalse()
    }, 333)     
    const timer = 
    state.playStartBusiness ?
    setTimeout(() => {
      document.body.classList.remove('startVideoShow')
    }, 4000) 
    :
    setTimeout(() => {
      document.body.classList.remove('startVideoHide')
    }, 4000) 
    return () => clearTimeout(timer)
  }, [state.playStartBusiness]);


  const [, forceUpdate] = useState();
  useLayoutEffect(() => {
    const timer =
    setTimeout(forceUpdate, 999);
    console.log("forceUpdate")
    return () => clearTimeout(timer)
  }, []);
  
  console.log("state.windowWidth: ", state.windowWidth)
  console.log("boxHeight: ", boxHeight)
  console.log("boxExtTop: ", boxExtTop)
  console.log("boxHeightRef: ", boxHeightRef)
  


  return (
    <>
      {(!state.playStartBusiness && state.windowWidth < 1078) &&
        <div 
          className={`videoLoop ${containerClass}`}
        >
          <video
            width={`${(state.windowWidth > 1320) ? 1180 : (state.windowWidth > 1078) ? 1050 : (state.windowWidth > 748) ? state.windowWidth - 80 : state.windowWidth}`}
            height='auto'
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={page.acfbusinesspagestart_layout.videoshortBusinessPageStartLayout.localFile.publicURL} type="video/mp4" />
          </video>
        </div>
      }

      <div className="section-header"></div>
      <div>
        <div 
          className={`boxEXT`}
          style={state.windowWidth > 1078 ? {
            // top: `${parseFloat(boxExtTop) > 308 ? boxExtTop : '308px'}`,
            // transform: 'translateY(-64px)',
          } : {}}
        >
          <div className="box box--start"
            style={{             
              top: `${state.windowWidth > 1078 ? `10vh` : `0px`}`,
              marginTop: `${state.windowWidth > 1078 ? 'unset' : (state.windowWidth > 748) ? state.windowWidth * 0.5625 - 74 : (state.windowWidth > 428) ? state.windowWidth * 0.5625 - 31 : state.windowWidth * 0.5625 - 30}px`,
            }}

            ref={boxHeightRef}

          >
            <div className={`boxDeco`}>
              <SvgBoxDeco />
            </div>

            {state.windowWidth < 1078 && <div
              className={`playHandler ${state.playStartBusiness === true ? 'videoPlay' : ''}`}
              style={{
                top: `${(state.windowWidth > 1320) ? 110 : (state.windowWidth > 1078) ? 118 : (state.windowWidth > 748) ? -324 : -188}px`,
              }}
            >
              <div className={`playHandlerInner`} onClick={hasPlayer} role="button" tabIndex="0" onKeyDown={hasPlayer}>
                <SvgPlay />
              </div>
            </div>}
            <h1>
              <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
                {graphqlResult.start.acfbusinesspagestart.subheaderBusinessPageStart}
              </span>
            </h1>
            <h2>{graphqlResult.start.acfbusinesspagestart.subheader2BusinessPageStart}</h2>
            {parse(page.content || '')}

            {graphqlResult.start.acfbusinesspagestart.ctaTextBusinessPageStart && <AniLink
              className="link fakeButton cta"
              to={graphqlResult.start.acfbusinesspagestart.ctaLinkBusinessPageStart}
              swipe
              direction="left"
              entryOffset={400}
              data-hover={graphqlResult.start.acfbusinesspagestart.ctaTextBusinessPageStart}
            >
              <span>{graphqlResult.start.acfbusinesspagestart.ctaTextBusinessPageStart}</span>
            </AniLink>}

          </div>
        </div>
      </div>
    </>
  )
}
export default Start