import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import BusinessLayout from 'src/components/layouts/businessLayout'
import Seo from 'src/components/seoWp'

import Start from 'src/components/business/pages/start'
import Offer from 'src/components/business/pages/offer'
import Benefits from 'src/components/business/pages/benefits'
import Order from 'src/components/business/pages/order'
import AboutUs from 'src/components/business/pages/aboutUs'
import Contact from 'src/components/business/pages/contact'
import ThankYou from 'src/components/business/pages/thankYou'
import Reviews from 'src/components/business/pages/reviews'
import Clients from 'src/components/business/pages/clients'
import Default from 'src/components/business/pages/default'
import OfferDetails from 'src/components/business/pages/offerDetails'

// import {
//   GlobalStateContext,
//   GlobalDispatchContext,
// } from 'src/context/GlobalContextProvider'

// import 'src/styles/global.scss'
// import 'src/styles/swiper.scss'
import 'src/styles/business.scss'

  
const BusinessPageTemplate = ({ data: { page, subpage, subpageMenu, subpage2lev }, pageContext, location }) => {
  
  // const state = useContext(GlobalStateContext)
  // const dispatch = useContext(GlobalDispatchContext)

  // console.log(subpage2lev)

  // const currentSectionUpdate = () => {
  //   dispatch({ type: 'CURRENT_SECTION', value: pageContext.siteSlug })
  // }
  const sessionStorageUpdate = () => {
    window.localStorage.setItem("loco", pageContext.siteSlug)
  }
  useEffect(() => {
    // state.currentSection !== pageContext.siteSlug &&
    window.localStorage.getItem("loco") !== pageContext.siteSlug &&
    // currentSectionUpdate()
    sessionStorageUpdate()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  if(typeof page.subpage2lev !== 'undefined') {
    // console.log('PAGE THAT SHOULD HAVE 3 LEVEL URL')
    page.AcfGatsbyId = page.subpage2lev.AcfGatsbyId
    page.acffreshmailsubs = subpage2lev.acffreshmailsubs
  }

  page.subpage = pageContext.subpage
  page.subpage2lev = pageContext.subpage2lev
  if(page.subpage2lev){
    page.subpage2lev.acfbusinessofferdetails = subpage2lev.acfbusinessofferdetails    
  }

  // console.log(page)
  // console.log(pageContext)

  const contentMap = {
    start: Start,
    offer: Offer,
    benefits: Benefits,
    'placing-order': Order,
    'about-us': AboutUs,
    contact: Contact,
    'thank-you': ThankYou,
    reviews: Reviews,
    clients: Clients,
    'offer-details': OfferDetails,
  }
  const Content = page.AcfGatsbyId.pageid in contentMap ? contentMap[page.AcfGatsbyId.pageid] : Default

  return (
    <React.Fragment>
      <BusinessLayout pageContext={pageContext} page={page} subpage={subpage} subpage2lev={subpage2lev}>
        <Seo 
          seo={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.seo : page.seo} 
          site={pageContext.siteSlug} 
          // slug={page.slug} 
          slug={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.slug : page.slug}
          gatsbyId={page.AcfGatsbyId.pageid} 
          subpageid={(typeof pageContext.subpage !== 'undefined' && pageContext.subpage.AcfGatsbyId.pageid)}
          subpage2levid={typeof pageContext.subpage2lev !== 'undefined' && pageContext.subpage2lev.AcfGatsbyId.pageid}      
          siteSlug={pageContext.realSlugs[pageContext.siteSlug]}
        />
        <Content pageContext={pageContext} page={page} subpage={subpage} subpageMenu={subpageMenu} subpage2lev={subpage2lev} location={location} />
      </BusinessLayout>
    </React.Fragment>
  )
}

export default BusinessPageTemplate

export const pageQuery = graphql`
  query BusinessPageById($id: String!, $subpageId: String!, $subpage2levId: String!,$pagePath: String!) {
    page: wpCptBusinessPage(id: { eq: $id }) {
      slug
      id
      title
      content
      AcfGatsbyId {
        pageid
      }
      acffreshmailsubs {
				freshmailSubscriptionCheckbox
        freshmailSubscriptionsListId
        checkboxDescriptionForUsers
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
        schema {
          raw
        }
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid (maxWidth: 1600, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      acfbusinesspagestart_layout {
        videolongBusinessPageStartLayout
        videoshortBusinessPageStartLayout {
          localFile {
            publicURL
          }
        }
      }


    }

    subpageMenu: wpMenuItem(childItems: {nodes: {elemMatch: {path: {eq: $pagePath}}}}) {
      childItems {
        nodes {
          id
          path
          label
          childItems {
            nodes {
              id
              path
              label
            }
          }
        }
      }
    }

    subpage: wpCptBusinessPage(id: { eq: $subpageId }) {
      slug
      title
      id
      content
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      acfbusinesspageofferbenefits {
        gifFileBusinessPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        fallbackFileBusinessPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        ctaTextBusinessPageOfferBenefits
        ctaLinkBusinessPageOfferBenefits     
        ctaTextBusinessPageOfferBenefitsSecond
        ctaLinkBusinessPageOfferBenefitsSecond      
      }

    }
    subpage2lev: wpCptBusinessPage(id: { eq: $subpage2levId }) {
      acffreshmailsubs {
				freshmailSubscriptionCheckbox
        freshmailSubscriptionsListId
        checkboxDescriptionForUsers
      }
      acfbusinessofferdetails{
        bannergroup{
          glowneZdjecie{
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tresc
          przyciskPodTrescia{
            naglowekDlaPrzycisku
            linkPrzycisku
            napisNaPrzycisku
          }
        }
        produktygrupa{
          tytulSekcji
          pojedynczyProdukt{
            tytul
            sliderZdjecia{
              grupaZdjec{
                zdjecie{
                  altText
                  description
                  title
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 60) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            tresc
            cena
            napisNaPrzycisku
            linkNaPrzycisku
          }
        }
        dlaczegowartogrupa{
          tytulSekcji
          zaleta{
            ikona{
              localFile {
                publicURL
              }
            }
            nazwa
            opis
          }
        }
        zobaczrealizacjegrupa{
          tlo{
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tytulOdnosnika
          linkOdnosnika
        }
        sprawdzinneproduktygrupa{
          zdjecie{
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tytulNadTrescia
          tresc
          przycisk{
            napisNaPrzycisku
            linkPrzycisku
          }
        }
        kontaktgrupa{
          tytul
          tresc
          kontaktContent
          naglowekDlaPrzycisku
          napisNaPrzycisku
          linkPrzycisku
        }
        mediaspolecznosciowegrupa{
          tytul
        }
      }
      slug
      title
      id
      content
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      acfbusinesspageofferbenefits {
        gifFileBusinessPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        fallbackFileBusinessPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        ctaTextBusinessPageOfferBenefits
        ctaLinkBusinessPageOfferBenefits       
        ctaTextBusinessPageOfferBenefitsSecond
        ctaLinkBusinessPageOfferBenefitsSecond      
      }
    }
  }
`
