import React, { useState, useEffect } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useStaticQuery, graphql } from 'gatsby'
import offerIcon from '../../../../static/offerIconBis.svg'
import {isSafari, isIE, isIOS, isEdge} from 'react-device-detect';
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'


const Page = ({ page }) => {
  return (
    <div>
      <div className="section-header">
        <h1>{page.title}</h1>
      </div>
      <div className="box box--offer">
        <img src="/offerIcon.svg" height="100px" alt=""></img>
      </div>
    </div>
  )
}

const Subpage = ({ pageContext, page, subpage, subpageMenu }, graphqlResult ) => {
  console.log(subpageMenu)

  const nav = subpageMenu?.childItems.nodes || []
  const benefitsACF = subpage.acfbusinesspageofferbenefits
  const gif360 = benefitsACF.gifFileBusinessPageOfferBenefits && benefitsACF.gifFileBusinessPageOfferBenefits.localFile
  const gif360Alt = benefitsACF.gifFileBusinessPageOfferBenefits && benefitsACF.gifFileBusinessPageOfferBenefits.altText

  const gif360fallback = benefitsACF.fallbackFileBusinessPageOfferBenefits && benefitsACF.fallbackFileBusinessPageOfferBenefits.localFile
  const gif360fallbackAlt = benefitsACF.fallbackFileBusinessPageOfferBenefits && benefitsACF.fallbackFileBusinessPageOfferBenefits.altText
  const fallbackCondition = (isIOS || isSafari || isIE || isEdge) ? true : false

  const currentPageIndex = nav.findIndex(x => x.path === pageContext.pagePath)  
  
  const[preloader, unsetPreloader] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      unsetPreloader(false)
    }, 5500);
    return () => clearTimeout(timer);
  }, [])

  const onLoadGif = () => {
    unsetPreloader(false)
    console.log('.           ...gif zaadowany')
  }


  return (
    <div className={`offer`}>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
        <div className="section-nav">
          {nav.map((item, idx) => (
            <AniLink
              key={idx}
              to={item.path}
              className={`${pageContext.pagePath === item.path ? 'section-nav_link--active' : ''} fakeButton menuLink`}
              swipe
              direction={idx > currentPageIndex ? 'left' : 'right'}
              data-hover={item.label}
            >
              {item.label}
            </AniLink>
          ))}
        </div>
      </div> 

      <div className="offer-content">

        <div className={`offer_spin`}>
          
          {(preloader && pageContext.subpage.AcfGatsbyId.pageid !== 'offer-sets') && 
            <div 
              className={`gif360placeholder`}                    
            >
              <img alt="" src={offerIcon} style={{maxWidth:'36px'}}></img>
              <span style={{color:'#D4AB4A'}}>{`loading...`}</span>
            </div>
          }
          {gif360 ? <img
            src={!fallbackCondition ? gif360.publicURL : gif360fallback.publicURL}
            alt={!fallbackCondition ? gif360Alt : gif360fallbackAlt}
            className={`gif360`}
            style={{maxWidth:'100%',maxHeight:'120%',marginTop:'-10%'}}
            onLoad={onLoadGif}
          ></img> 
          : ''
          }
        </div> 

        <div className={`boxEXT`}>
          <div className="box box--offer boxWithDeco">
            <div className={`boxDeco`}>
              <SvgBoxDeco />
            </div>

            <h2>{subpage.title}</h2>

            {subpage.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: subpage.content }}></div>}

            {graphqlResult.offer.acfbusinesspageoffer.subheaderBusinessPageOffer && <p className={`buttonHeading`}>
              {graphqlResult.offer.acfbusinesspageoffer.subheaderBusinessPageOffer}
            </p>}

            <div className={`buttonsWrapper`}>

              {benefitsACF.ctaLinkBusinessPageOfferBenefitsSecond ?
                <button>
                {/* <a 
                  href={benefitsACF.ctaLinkBusinessPageOfferBenefitsSecond} 
                  className="cta2"
                  rel="noreferrer"
                >
                  {benefitsACF.ctaTextBusinessPageOfferBenefitsSecond}
                </a> */}
                <AniLink 
                  to={benefitsACF.ctaLinkBusinessPageOfferBenefitsSecond} 
                  className="cta2"
                  swipe
                  direction={"down"}
                >
                  {benefitsACF.ctaTextBusinessPageOfferBenefitsSecond}
                </AniLink>
                </button> :
                ""
              }

              {graphqlResult.offer.acfbusinesspageoffer.ctaLinkBusinessPageOffer ?
                <button>
                <a 
                  href={benefitsACF.ctaLinkBusinessPageOfferBenefits} 
                  className="cta2"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  {benefitsACF.ctaTextBusinessPageOfferBenefits}
                </a>
                </button> :
                <div style={{height:'72px',marginTop: '44px'}}></div>
              }

            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

const Offer = (props) => {
    const graphqlResult = useStaticQuery(graphql`
    query BusinessOfferPage {
      offer: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "offer"}}) {      
				acfbusinesspageoffer {
					subheaderBusinessPageOffer
          ctaTextBusinessPageOffer
          ctaLinkBusinessPageOffer
          buttonMoreBusinessPageOffer
        }
      }
    }
  `)
  return props.subpage ? Subpage(props,graphqlResult) : Page(props)
}
export default Offer