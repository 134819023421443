import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'

const Page = ({ page, pageContext }) => {
  const graphqlResult = useStaticQuery(graphql`
  query BusinessAboutus {
    aboutus: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "about-us"}}) {      
      acfbusinesspageaboutus {
        buttonMoreBusinessPageAboutus
        buttonLessBusinessPageAboutus
      }
    }
  }
`)

const [readMore, setReadMore] = useState(false)

  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className={`box box--aboutus ${readMore ? 'box--readmore' : ''} boxWithDeco`}>
        <div className={`boxDeco ${readMore ? 'boxDecoDead' : ''}`}>
          <SvgBoxDeco />
        </div>
        {page.content && <div className={`wpContent card`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        <button className="readMore cta2" onClick={() => setReadMore(!readMore)}>{readMore ? graphqlResult.aboutus.acfbusinesspageaboutus.buttonLessBusinessPageAboutus : graphqlResult.aboutus.acfbusinesspageaboutus.buttonMoreBusinessPageAboutus}</button>
      </div>
    </div>
  )
}
const AboutUs = (props) => {
  return Page(props)
}
export default AboutUs