import React from 'react'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'

const Clients = ({ page }) => {
  return (
    <div>
      <div className="section-header">
        <h1 className={``}>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className={`boxEXT boxEXT--clients`}>
        <div className="box box--clients boxWithDeco">
          <div className={`boxDeco `}>
            <SvgBoxDeco />
          </div>
          {page.content && <div className={`wpContent clients`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        </div>
      </div>
    </div>
  )
}
export default Clients